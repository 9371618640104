.btn_action {
	width: 50px;
	height: 50px;
	border-radius: 50px;
	background-color: rgb(69, 225, 43);
	border: 2px solid rgb(69, 225, 43);
	cursor: pointer;
	position: fixed;
	bottom: 30px;
	right: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	z-index: 999;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
}

.modal {
	z-index: 9999999;
	box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
	width: 400px;
	height: auto;
	background:#2D8320;
	overflow: hidden;
	border-radius: 20px;
	position: fixed;
	top: 30px;
	z-index: 1000;
	transition: all 0.5s;
	right: -400px;
}
.modal.active {
	right: 30px;
	transition: all 0.5s;
}
.modal_header {
	height: 50px;
	display: flex;
	flex-direction: row;
	position: relative;
	align-items: center;
	padding-left: 15px;
	padding-right: 15px;
}

.modal_header .modal_button_close {
	border: 0px;
	width: 30px;
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: absolute;
	border-radius: 100%;
	background-color: black;
	cursor: pointer;
	right: 10px;
	top: 10px;
	cursor: pointer;
}

.modal_header .title {
	font-size: 16px;
	color: white;
    font-family: 'Oswald', sans-serif;
}

.modal .modal_content {
	background-color: #eff1f5;
	border-radius: 0px;
	padding: 15px;
}

.actions {
	flex-direction: row;
	justify-content: space-between;
	flex-direction: row;
	display: flex;
	flex-wrap: wrap;
}


@media all and (max-width: 768px) {
	.modal {
		right:0px;
		bottom: -400px !important;
		top: inherit !important;
	}
	.modal.active {
		width: 100%;
		bottom: 0 !important;
		left: 0;
		right: 0px;
		border-radius: 20px 20px 0px 0px !important;
	}
	
	.modal_header .modal_button_close {
		right: 20px;
	}
}