.btn {
	width: 177px;
	height: 80px;
	border: 2px solid white;
	background-color: white;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 5px;
	position: relative;
	margin-bottom: 15px;
}

.btn  span{
	color: initial;
}
.btn.active {
	border-color: black;
	box-shadow: 0 2px 0px rgba(0, 0, 0, 0.09);
}

.active_label {
	display: none;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 5px;
	right: 5px;
	width: 20px;
	height: 20px;
	border-radius: 20px;
	background-color: rgba(0, 0, 0, 0.2);
}
.btn.active .active_label {
	display: flex;
}

.highlight_links {
	background-color: black !important;
	color: #ffba00 !important;
	text-decoration: underline !important;
}

ul.steep {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	height: 4px;
	margin-bottom: 5px;
	margin-top: 5px;
}

ul.steep li {
	width: 35px;
	height: 4px;
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 4px;
	margin-left: 2px;
	margin-right: 2px;
}
ul.steep li:first-child {
	margin-left: 0px;
}
ul.steep li:last-child {
	margin-right: 0px;
}
ul.steep li.active {
	background-color: rgba(0, 0, 0, 0.8);
}

.letter_spacing_0 {
	letter-spacing: 0.5rem;
}
.letter_spacing_1 {
	letter-spacing: 0.7rem;
}
.letter_spacing_2 {
	letter-spacing: 0.9rem;
}
.line_height_0 {
	line-height: 2rem;
}

.line_height_1 {
	line-height: 3rem;
}
.line_height_2 {
	line-height: 3.5rem;
}
